//http://codepen.io/webcane/pen/LBspI
jQuery(document).ready(function($) {
  $("#call-me-form").submit(function() {
    var tel = $("#tel-2").val(); // get tel field value
    var subject = $("#subject").val();
    var toEmail = $("#toEmail").val();
    var toName = $("#toName").val();
    var fromEmail = $("#fromEmail").val();
    var success = $("#success").val();
    var error = $("#error").val();

    // if(validateTel(tel)) {
    //   console.log('valid tel');
    //   return true;
    // } else {
    //   console.log('invalid tel');
    //   return false;
    // }

    $.ajax({
        type: "POST",
        url: "https://mandrillapp.com/api/1.0/messages/send.json",
        data: {
          'key': 'GWY1LGmmhPV6gtvKMEkHWA',
          'message': {
            'from_email': fromEmail,
            'from_name': 'Opkaldsservice',
            'subject': subject,
            'html': 'Ring til tlf.nr: ' + tel + ' vedr. ' + subject,
            'to': [{
              'email': toEmail,
              'name': toName,
              'type': 'to'
            }],
            "bcc_address": "kasper@detnyesort.dk"
          }
        }
      })
      .done(function(response) {
        $(".alerts-tel").append('<div class="alert alert-success" role="alert"><svg width="24px" height="24px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><path d="M170.718,216.482l-29.118,29.118l93.6,93.6l208,-208l-29.118,-29.118l-178.882,177.836l-64.482,-63.436Zm251.682,39.518c0,91.518 -74.883,166.4 -166.4,166.4c-91.517,0 -166.4,-74.882 -166.4,-166.4c0,-91.518 74.882,-166.4 166.4,-166.4c15.6,0 31.2,2.082 45.764,6.241l32.236,-32.241c-23.918,-10.4 -49.918,-15.6 -78,-15.6c-114.4,0 -208,93.6 -208,208c0,114.4 93.6,208 208,208c114.4,0 208,-93.6 208,-208l-41.6,0Z" style="fill-rule:nonzero;"/></svg>'+success+'</div>');
        $("#tel-2").val('');
      })
      .fail(function(response) {
        $(".alerts-tel").append('<div class="alert alert-warning" role="alert">'+error+'</div>');
      });
    return false; // prevent page refresh
  });
});
// function validateEmail($email) {
//  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
//  return emailReg.test( $email );
// }
// function validateTel($tel) {
//  var telReg = /^(\d{2})?\d{8}$/;
//  return telReg.test( $tel );
// }
