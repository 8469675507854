//load immediately
// (function($) {
//
// })(jQuery);

//load after page loaded
jQuery(document).ready(function($) {
  $(function() {
    //caches a jQuery object containing the header element
    var header = $(".dns");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 150) {
            header.addClass("scroll-down");
        } else {
            header.removeClass("scroll-down");
        }
    });
});
});
