//load immediately
// (function($) {
//
// })(jQuery);

//load after page loaded
jQuery(document).ready(function($){
    //Scroll to top in div overflow-y
  	$('.scrollToTopMD').click(function(){
  		$('body').animate({scrollTop : 0},400);
  		return false;
  	});
    //Scroll to top normal
    $('.scrollToTop').click(function(){
  		$('body').animate({scrollTop : 0},300);
  		return false;
  	});
});
